import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsConditionsComponent } from './terms-conditions.component';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';

@NgModule({
    declarations: [
        TermsConditionsComponent
    ],
    imports: [
        CommonModule,
        SidebarModule,
        ButtonModule,
     ],
    exports: [
        TermsConditionsComponent
    ]
})
export class TermsConditionsModule {}