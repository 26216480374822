<div class="p-text-center">
    <a [ngStyle]="{'cursor' : 'pointer'}" (click)='showTermsOfUse()'>Conditions d'utilisation</a> - 
    <a [ngStyle]="{'cursor' : 'pointer'}" (click)='showPrivacyPolicy()'>Politique de confidentialité</a> - 
    <a [ngStyle]="{'cursor' : 'pointer'}" (click)='showLegalNotice()'>Mentions légales</a>
</div>

<p-sidebar [(visible)]='termsOfUse'[fullScreen]="true" [baseZIndex]="10000" [style]="{'overflow': 'scroll'}" (onHide)='hideTermsOfUse()'>
    <h2>Conditions d'utilisation</h2>
    <h3>ARTICLE 1. INFORMATIONS LÉGALES</h3>
    <p>En vertu de l'article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, 
        il est précisé dans cet article l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi. Le site Familiarus.com est édité par :
    Holystrom SPRL, dont le siège siège social est situé à l'adresse suivante : Boulevard de France, 9, 1420 Braine-l'Alleud, Belgique, 
        avec le numéro d’entreprise suivant : 0838.297.259.
    Téléphone : +33 1 44 01 45 65 / Adresse e-mail : info@holystrom.com.
    Le directeur de publication du site est : Monsieur Melchior Colmant.
    Le site Familiarus.com est hébergé par :
    Amazon Web Services, Inc., dont le siège est situé à l'adresse ci-après :
    P.O. Box 81226, Seattle, WA 98108-1226, USA.
    <h3>ARTICLE 2. PRÉSENTATION DU SITE</h3>
    <p>Le site Familiarus.com a pour objet :
    La création et le maintient de son arbre généalogique.</p>
    <h3>ARTICLE 3. CONTACT</h3>
    <p>Pour toute question ou demande d'information concernant le site, ou tout signalement de contenu ou d'activités illicites, 
        l'utilisateur peut contacter l'éditeur à l'adresse e-mail suivante : info@holystrom.com ou adresser un courrier recommandé 
        avec accusé de réception à : Holystrom SPRL - Boulevard de France, 9, 1420 Braine-l'Alleud, Belgique</p>    
    <h3>ARTICLE 4. ACCEPTATION DES CONDITIONS D'UTILISATION</h3>
    <p>L'accès et l'utilisation du site sont soumis à l'acceptation et au respect des présentes Conditions Générales d'Utilisation.
    L'éditeur se réserve le droit de modifier, à tout moment et sans préavis, le site et ses services ainsi que les présentes CGU, notamment 
    pour s'adapter aux évolutions du site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.
    Il est donc conseillé à l'utilisateur de se référer avant toute navigation à la dernière version des CGU, accessible à tout moment sur le site. En cas de désaccord 
    avec les CGU, aucun usage du site ne saurait être effectué par l'utilisateur.</p>
    <h3>ARTICLE 5. ACCÈS ET NAVIGATION</h3>
    <p>L'accès au site et son utilisation sont réservés aux personnes majeures. L'éditeur sera en droit de demander une justification de l'âge de l'utilisateur, 
        et ce par tout moyen. L'éditeur met en œuvre les solutions techniques à sa disposition pour permettre l'accès au site 24 heures sur 24, 7 jours sur 7. 
        Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l'accès au site ou à certaines pages de celui-ci afin de procéder à des mises à jours, 
        des modifications de son contenu ou tout autre action jugée nécessaire au bon fonctionnement du site. La connexion et la navigation sur le site Familiarus.com 
        valent acceptation sans réserve des présentes Conditions Générales d'Utilisation, quelques soient les moyens techniques d'accès et les terminaux utilisés.
        Les présentes CGU s'appliquent, en tant que de besoin, à toute déclinaison ou extension du site sur les réseaux sociaux et/ou communautaires existants ou à venir.</p>
    <h3>ARTICLE 6. GESTION DU SITE</h3> 
    <p>Pour la bonne gestion du site, l'éditeur pourra à tout moment :
    Suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internaute ; 
    Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales ; 
    Suspendre le site afin de procéder à des mises à jour.</p>
    <h3>ARTICLE 7. RESPONSABILITÉS</h3>
    <p>L'éditeur n'est responsable que du contenu qu'il a lui-même édité.
    L'éditeur n'est pas responsable :
    En cas de problématiques ou défaillances techniques, informatiques ou de compatibilité du site avec un matériel ou logiciel quel qu'il soit ; 
    Des dommages directs ou indirects, matériels ou immatériels, prévisibles ou imprévisibles résultant de l'utilisation ou des difficultés d'utilisation 
    du site ou de ses services ; 
    Des caractéristiques intrinsèques de l'Internet, notamment celles relatives au manque de fiabilité et au défaut de sécurisation des informations y circulant ; 
    Des contenus ou activités illicites utilisant son site et ce, sans qu'il en ait pris dûment connaissance au sens de la Loi n° 2004-575 du 21 juin 2004 pour 
    la confiance dans l'économie numérique et la Loi n°2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard de traitement de données 
    à caractère personnel. 
    Par ailleurs, le site ne saurait garantir l'exactitude, la complétude, et l'actualité des informations qui y sont diffusées.
    L'utilisateur est responsable :
    De la protection de son matériel et de ses données ;
    De l'utilisation qu'il fait du site ou de ses services ;
    S'il ne respecte ni la lettre, ni l'esprit des présentes CGU.</p>
    <h3>ARTICLE 8. LIENS HYPERTEXTES</h3>
    <p>Le site peut contenir des liens hypertextes pointant vers d'autres sites internet sur lesquels Familiarus.com n'exerce pas de contrôle. Malgré 
    les vérifications préalables et régulières réalisés par l'éditeur, celui-ci décline tout responsabilité quant aux contenus qu'il est possible de trouver sur ces sites.
    L'éditeur autorise la mise en place de liens hypertextes vers toute page ou document de son site sous réserve que la mise en place de ces liens ne soit pas réalisée 
    à des fins commerciales ou publicitaires.
    En outre, l'information préalable de l'éditeur du site est nécessaire avant toute mise en place de lien hypertexte.
    Sont exclus de cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique, pornographique, xénophobe ou pouvant porter 
    atteinte à la sensibilité du plus grand nombre.
    Enfin, Familiarus.com se réserve le droit de faire supprimer à tout moment un lien hypertexte pointant vers son site, si le site l'estime non conforme à sa politique éditoriale.</p>
    <h3>ARTICLE 9 : CONFIDENTIALITÉ</h3>
    <p>En plus des présentes Conditions Générales, le site dispose d'une politique de confidentialité qui décrit la manière dont les données à caractère personnel 
    sont traitées lorsque l'utilisateur se rend sur le site, ainsi que la manière dont les cookies sont utilisés.
    En naviguant sur le site, l'utilisateur déclare avoir également pris connaissance de la politique de confidentialité susmentionnée.</p>
    <h3>ARTICLE 10. PROPRIÉTÉ INTELLECTUELLE</h3>
    <p>La structuration du site mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications informatiques qui le composent sont la propriété de 
    l'éditeur et sont protégés comme tels par les lois en vigueur au titre de la propriété intellectuelle. Toute représentation, reproduction, adaptation ou exploitation 
    partielle ou totale des contenus, marques déposées et services proposés par le site, par quelque procédé que ce soit, sans l'autorisation préalable, expresse et écrite 
    de l'éditeur, est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L. 335-2 et suivants du Code de la propriété intellectuelle. 
    Et ce, à l'exception des éléments expressément désignés comme libres de droits sur le site. L'accès au site ne vaut pas reconnaissance d'un droit et, de manière générale, 
    ne confère aucun droit de propriété intellectuelle relatif à un élément du site, lesquels restent la propriété exclusive de l'éditeur. Il est interdit à l'utilisateur 
    d'introduire des données sur le site qui modifieraient ou qui seraient susceptibles d'en modifier le contenu ou l'apparence.</p>
    <h3>ARTICLE 11. LOI APPLICABLE ET JURIDICTION COMPÉTENTE</h3>
    <p>Les présentes Conditions Générales d'Utilisation sont régies par la loi belge. En cas de différend et à défaut d'accord amiable, le litige sera porté devant les 
    tribunaux belges conformément aux règles de compétence en vigueur.</p>
    <p>Le site Familiarus.com vous souhaite une excellente navigation !</p>
    <div class="p-text-center">
        <p-button label="Retour" styleClass="p-button-link" (click)='hideTermsOfUse()'></p-button>
    </div>
</p-sidebar>

<p-sidebar [(visible)]='privacyPolicy'[fullScreen]="true" [baseZIndex]="10000" [style]="{'overflow': 'scroll'}" (onHide)='hidePrivacyPolicy()'>
    <h2>Politique de confidentialité</h2>
    <h3>ARTICLE 1 : PRÉAMBULE</h3>
    <p>Cette politique de confidentialité s'applique au site : Familiarus.com.
    La présente politique de confidentialité a pour but d'exposer aux utilisateurs du site :
    La manière dont sont collectées et traitées leurs données à caractère personnel. Doivent être considérées comme données personnelles toutes les données étant 
    susceptibles d'identifier un utilisateur. Il s'agit notamment du prénom et du nom, de l'âge, de l'adresse postale, l'adresse mail, la localisation de l'utilisateur 
    ou encore son adresse IP ;
    Quels sont les droits des utilisateurs concernant ces données ;
    Qui est responsable du traitement des données à caractère personnel collectées et traitées ;
    A qui ces données sont transmises ;
    Eventuellement, la politique du site en matière de fichiers "cookies".
    Cette politique de confidentialité complète les mentions légales et les conditions d'utilisation que les utilisateurs peuvent consulter sur ce site.</p>
    <h3>ARTICLE 2 : PRINCIPES GÉNÉRAUX EN MATIÈRE DE COLLECTE ET DE TRAITEMENT DE DONNÉES</h3>
    <p>Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données des utilisateurs du site respectent 
    les principes suivants :
    Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de l'utilisateur propriétaire des données. 
    A chaque fois que des données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons 
    ses données sont collectées ; 
    Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés dans 
    les présentes conditions générales d'utilisation ; 
    Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des objectifs poursuivis par le site sont collectées ; 
    Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont l'utilisateur est informé.
    Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s'engage à garantir l'intégrité et la confidentialité des données 
    collectées.
    Afin d'être licites, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et le traitement des données à caractère personnel 
    ne pourront intervenir que s'ils respectent au moins l'une des conditions ci-après énumérées :
    L'utilisateur a expressément consenti au traitement ; 
    Le traitement est nécessaire à la bonne exécution d'un contrat ; 
    Le traitement répond à une obligation légale ; 
    Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ; 
    Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de l'exercice de l'autorité publique ; 
    Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et privés poursuivis par le responsable du traitement 
    ou par un tiers.</p>    
    <h3>ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE CADRE DE LA NAVIGATION SUR LE SITE</h3>
    <h4>A. DONNÉES COLLECTÉES ET TRAITÉES ET MODE DE COLLECTE</h4>
    <p>Les données à caractère personnel collectées sur le site Familiarus.com sont les suivantes :
    -Adresse email
    Ces données sont collectées lorsque l'utilisateur s'inscrit sur le site.
    Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées 
    pour une durée de : 10 ans.
    La collecte et le traitement des données répondent aux finalités suivantes :
    Les données de localisation et d’adresse IP sont enregistrées afin de mieux cibler les publicités proposées à l’utilisateur.
    Les données personnelles sont utilisées à des fins commerciales.</p>
    <h4>B. TRANSMISSION DES DONNÉES A DES TIERS</h4>
    <p>Les données à caractère personnel collectées par le site ne sont transmises à aucun tiers, et ne sont traitées que par l'éditeur du site.</p>
    <h4>C. HÉBERGEMENT DES DONNÉES</h4>
    <p> Le site Familiarus.com est hébergé par :
    Amazon Web Services, Inc., dont le siège est situé à l'adresse ci-après :
    P.O. Box 81226, Seattle, WA 98108-1226, USA.
    Les données collectées et traitées par le site sont transférées vers le(s) pays suivant(s) : Belgique.
    <h3>ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES</h3>
    <h4>A. LE RESPONSABLE DU TRAITEMENT DES DONNÉES</h4>
    <p>Le responsable du traitement des données à caractère personnel est : Melchior Colmant. Il peut être contacté de la manière suivante :
    Par email : info@holystrom.com
    Le responsable du traitement des données est chargé de déterminer les finalités et les moyens mis au service du traitement des données à caractère personnel.</p>
    <h4>B. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</h4>
    <p>Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur 
    n'en ait été informé et à respecter les finalités pour lesquelles ces données ont été collectées.
    Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.
    Un certificat SSL ("Secure Socket Layer" Certificate) a pour but de sécuriser les données échangées entre l'utilisateur et le site.
    De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela 
    n'entraîne pour lui des formalités, coûts et démarches disproportionnés.
    Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est compromise, le responsable du traitement 
    s'engage à informer l'utilisateur par tout moyen.</p>
    <h3>ARTICLE 5 : DROITS DE L'UTILISATEUR</h3>
    <p>Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.
    Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui communiquer : ses prénom et nom ainsi que son adresse e-mail.
    Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.</p>
    <h4>PRÉSENTATION DES DROITS DE L'UTILISATEUR EN MATIÈRE DE COLLECTE ET TRAITEMENT DE DONNÉES</h4>
    <h4>a. Droit d'accès, de rectification et droit à l'effacement</h4>
    <p>L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :
    L’utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en précisant l’objet de sa demande et en utilisant 
    l’adresse e-mail de contact qui est fournie plus haut.</p>
    <h4>b. Droit à la portabilité des données</h4>
    <p>L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre site, en se conformant à la procédure ci-après :
    L’utilisateur doit faire une demande de portabilité de ses données personnelles auprès du responsable du traitement des données, en envoyant un e-mail à l’adresse prévue 
    ci-dessus.
    <h4>c. Droit à la limitation et à l'opposition du traitement des données</h4>
    <p>L'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, 
    sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.
    Afin de demander la limitation du traitement de ses données ou de formuler une opposition au traitement de ses données, l'utilisateur doit suivre la procédure suivante :
    L’utilisateur doit faire une demande de limitation au traitement de ses données personnelles auprès du responsable du traitement des données, en envoyant un e-mail à 
    l’adresse prévue ci-dessus.</p>
    <h4>d. Droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé</h4>
    <p>Conformément aux dispositions du règlement 2016/679, l'utilisateur a le droit de ne pas faire l'objet d'une décision fondée exclusivement sur un procédé automatisé 
    si la décision produit des effets juridiques le concernant, ou l'affecte de manière significative de façon similaire.</p>
    <h4>e. Droit de déterminer le sort des données après la mort</h4>
    <p>Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 
    octobre 2016.</p>
    <h4>f. Droit de saisir l'autorité de contrôle compétente</h4>
    <p>Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, 
    ou, s'il pense qu'il est porté atteinte à l'un des droits énumérés ci-dessus, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des 
    Libertés, https://www.cnil.fr) ou tout juge compétent.</p>
    <h3>ARTICLE 6 : UTILISATION DES FICHIERS "COOKIES"</h3>
    <p>Le site a éventuellement recours aux techniques de "cookies".
    Un "cookie" est un fichier de petite taille (moins de 4 ko), stocké par le site sur le disque dur de l'utilisateur, contenant des informations relatives aux habitudes 
    de navigation de l'utilisateur.
    Ces fichiers lui permettent de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d'améliorer le service pour le confort de l'utilisateur.
    Pour l'utilisation de fichiers "cookies" impliquant la sauvegarde et l'analyse de données à caractère personnel, le consentement de l'utilisateur est nécessairement demandé.
    Ce consentement de l'utilisateur est considéré comme valide pour une durée de 13 (treize) mois maximum. A l'issue de cette période, le site demandera à nouveau l'autorisation 
    de l'utilisateur pour enregistrer des fichiers "cookies" sur son disque dur.
    <h4>a. Opposition de l'utilisateur à l'utilisation de fichiers "cookies" par le site</h4>
    <p>Il est porté à la connaissance de l'utilisateur qu'il peut s'opposer à l'enregistrement de ces fichiers "cookies" en configurant son logiciel de navigation.
    Pour information, l'utilisateur peut trouver aux adresses suivantes les démarches à suivre afin de configurer son logiciel de navigation pour s'opposer à l'enregistrement 
    des fichiers "cookies" :
    Chrome : https://support.google.com/accounts/answer/61416?hl=fr
    Firefox : https://support.mozilla.org/fr/kb/enable-and-disable-cookies-website-preferences
    Safari : http://www.apple.com/legal/privacy/fr-ww/
    Internet Explorer : https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies
    Opera : http://www.opera.com/help/tutorials/security/cookies/
    Dans le cas où l'utilisateur décide de désactiver les fichiers "cookies", il pourra poursuivre sa navigation sur le site. Toutefois, tout dysfonctionnement du site 
    provoqué par cette manipulation ne pourrait être considéré comme étant du fait de l'éditeur du site.</p>
    <h4>b. Description des fichiers "cookies" utilisés par le site</h4>
    <p>L'éditeur du site attire l'attention de l'utilisateur sur le fait que les cookies suivants sont utilisés lors de sa navigation :
    Collect
    Emetteur : Google Analytics
    Finalité : utilisé pour envoyer des informations à Google Analytics sur l’appareil et le comportement de l’utilisateur. Ces cookies suivent le visiteur à travers leurs 
    appareils et canaux marketing
    Politique de confidentialité : https://policies.google.com/privacy
    Google Adwords, YouTube & Doubleclick (pixel)
    Emetteur : Google
    Finalité : utilisé pour les mesures d’audience et performance des annonces, création de liste d’audience similaire, remarketing
    Politique de confidentialité : https://policies.google.com/privacy
    Adroll (pixel)
    Emetteur : Adroll
    Finalité : utilisé pour les mesures d’audience et performance des annonces, création de liste d’audience similaire, remarketing
    Politique de confidentialité : https://www.adrollgroup.com/privacy</p>
    <h3>ARTICLE 7 : CONDITIONS DE MODIFICATION DE LA POLITIQUE DE CONFIDENTIALITÉ</h3>
    <p>La présente politique de confidentialité peut être consultée à tout moment sur ce site.
    L'éditeur du site se réserve le droit de la modifier afin de garantir sa conformité avec le droit en vigueur.
    Par conséquent, l'utilisateur est invité à venir consulter régulièrement cette politique de confidentialité afin de se tenir informé des derniers changements 
    qui lui seront apportés.
    Il est porté à la connaissance de l'utilisateur que la dernière mise à jour de la présente politique de confidentialité est intervenue le : 02/01/2021.</p>
    <h3>ARTICLE 8 : ACCEPTATION PAR L'UTILISATEUR DE LA POLITIQUE DE CONFIDENTIALITÉ</h3>   
    <p>En naviguant sur le site, l'utilisateur atteste avoir lu et compris la présente politique de confidentialité et en accepte les conditions, 
    en ce qui concerne plus particulièrement la collecte et le traitement de ses données à caractère personnel, ainsi que l'utilisation de fichiers "cookies".</p>
    <div class="p-text-center">
        <p-button label="Retour" styleClass="p-button-link" (click)='hidePrivacyPolicy()'></p-button>
    </div>
</p-sidebar>

<p-sidebar [(visible)]='legalNotice'[fullScreen]="true" [baseZIndex]="10000" [style]="{'overflow': 'scroll'}" (onHide)='hideLegalNotice()'>
    <h2>Mentions légales</h2>
    <p>Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., 
        il est porté à la connaissance des utilisateurs et visiteurs du site Familiarus les présentes mentions légales.
    Le site Familiarus.com est accessible à l'adresse suivante : https://Familiarus.com (ci-après "le Site"). L'accès et l'utilisation du Site sont soumis aux présentes 
    " Mentions légales" détaillées ci-après ainsi qu'aux lois et/ou règlements applicables.
    La connexion, l'utilisation et l'accès à ce Site impliquent l'acceptation intégrale et sans réserve de l'internaute de toutes les dispositions des présentes mentions légales.</p>
    <h3>ARTICLE 1 - INFORMATIONS LÉGALES</h3>
    <p>En vertu de l'Article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé dans cet article l'identité des différents 
    intervenants dans le cadre de sa réalisation et de son suivi.</p>
    <h4>A. Editeur du site</h4>
    <p>Le site Familiarus.com est édité par : Holystrom SPRL
    Ayant son siège social à l'adresse suivante : Boulevard de France, 9, 1420, Braine-l'Alleud, Belgique, avec le numéro d’entreprise suivant : 0838.297.259.
    Téléphone : +33 1 44 01 45 65
    Adresse e-mail : info@holystrom.com
    ci-après " l'Éditeur "</p>
    <h4>B. Directeur de publication</h4>
    <p>Le Directeur de publication est : Melchior Colmant
    Adresse e-mail de contact : info@holystrom.com
    ci-après " le Directeur de publication "</p>
    <h4>C. Hébergeur du site</h4>
    <p>Le site Familiarus est hébergé par : Amazon Web Services, Inc.,
    dont le siège est situé à l'adresse suivante : P.O. Box 81226, Seattle, WA 98108-1226, USA.
    ci-après " l'Hébergeur "</p>
    <h4>D. Utilisateurs</h4>
    <p>Sont considérés comme utilisateurs tous les internautes qui naviguent, lisent, visionnent et utilisent le site Familiarus.com.
    ci-après les " Utilisateurs "
    <h3>ARTICLE 2 - ACCESSIBILITÉ</h3>
    <p>Le Site est par principe accessible aux Utilisateurs 24/24h et 7/7j, sauf interruption, programmée ou non, pour des besoins de maintenance ou en cas de force majeure.
    En cas d'impossibilité d'accès au Site, celui-ci s'engage à faire son maximum afin d'en rétablir l'accès. Le Site ne saurait être tenu pour responsable de tout dommage, 
    quelle qu'en soit la nature, résultant de son indisponibilité.</p>
    <h3>ARTICLE 3 - LOI APPLICABLE ET JURIDICTION</h3>
    <p>Les présentes Mentions Légales sont régies par la loi belge. En cas de différend et à défaut d'accord amiable, le litige sera porté devant les tribunaux belges conformément 
    aux règles de compétence en vigueur.</p>
    <h3>ARTICLE 4 - CONTACT</h3>
    <p>Pour tout signalement de contenus ou d'activités illicites, l'Utilisateur peut contacter l'Éditeur à l'adresse suivante : info@holystrom.com, ou par courrier recommandé 
    avec accusé de réception adressé à l'Éditeur aux coordonnées précisées dans les présentes mentions légales.</p>
    <p>Le site Familiarus.com vous souhaite une excellente navigation !</p>
    <div class="p-text-center">
        <p-button label="Retour" styleClass="p-button-link" (click)='hideLegalNotice()'></p-button>
    </div>
</p-sidebar>