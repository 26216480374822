import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {

  element: string;
  termsOfUse = false;
  privacyPolicy = false;
  legalNotice = false;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.element = params.element;
    });
  }

  showTermsOfUse() {
    this.termsOfUse = true;
  }

  hideTermsOfUse() {
    this.termsOfUse = false;
  }

  showPrivacyPolicy() {
    this.privacyPolicy = true;
  }

  hidePrivacyPolicy() {
    this.privacyPolicy = false;
  }

  showLegalNotice() {
    this.legalNotice = true;
  }

  hideLegalNotice() {
    this.legalNotice = false;
  }

}
