import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { AuthState, CognitoUserInterface, FormFieldTypes, onAuthUIStateChange } from '@aws-amplify/ui-components';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'FamilyTreeApp';
  user: CognitoUserInterface | undefined;
  authState: AuthState;
  signUpFormFields: FormFieldTypes;
  isLoggedIn = false;
  showTC = false;

  constructor(private ref: ChangeDetectorRef)
  {
    this.signUpFormFields = [
      {
        type: "email",
        label: "Adresse email",
        placeholder: "Insérez votre adresse email",
        required: true,
      },
      {
        type: "password",
        label: "Mot de passe",
        placeholder: "Créez votre mot de passe",
        required: true,
      }
    ];
  }

  ngOnInit() {
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData as CognitoUserInterface;
      this.ref.detectChanges();
    });
    setTimeout(()=> this.showTC = true, 1000)
  }

  ngOnDestroy() {
    return onAuthUIStateChange;
  }
}

