export const dict = {
	fr: {
		Hello: 'Bonjour',
		'Loading...': 'Chargement...',
		'Sign In': 'Se connecter',
		'Sign In with Amazon': 'Se connecter avec Amazon',
		'Sign In with Facebook': 'Se connecter avec Facebook',
		'Sign In with Google': 'Se connecter avec Google',
		'Sign in with AWS': 'Se connecter avec AWS',
		'Sign Up': "S'inscrire",
		'Sign Out': 'Déconnexion',
        'Forgot your password?': 'Mot de passe oublié? ',
        'No account?': 'Pas de compte? ',
        'Have an account?': 'Vous avez un compte? ',
		'Email Address *': 'Adresse email *',
		'Username *': 'Adresse email *',
		'Enter your email address': 'Insérez votre email',
		'Confirm Sign up': 'Confirmer l\'inscription',
		'Lost your code?': 'Code perdu? ',
		'Cannot reset password for the user as there is no registered/verified email or phone_number': 
			'Impossible de réinitialiser le mot de passe de l\'utilisateur car il n\'y a pas d\'e-mail ou de numéro de téléphone enregistré/vérifié',
		'Incorrect username or password.': 'Nom d\'utilisateur ou mot de passe incorect.',
		'Password did not conform with policy: Password not long enough': 'Votre mot de passe doit contenir 8 caractères au minimum',
		'1 validation error detected: Value at \'password\' failed to satisfy constraint: Member must have length greater than or equal to 6': 
			'Votre mot de passe doit contenir 8 caractères au minimum',
		'Password cannot be empty': 'Votre mot de passe ne peut être vide.',
		'Custom auth lambda trigger is not configured for the user pool.': 'Votre mot de passe ne peut être vide.',
		Username: 'Nom d\'utilisateur',
		Password: 'Mot de passe',
		'Change Password': 'Modifier le mot de passe',
		Change: 'Modifier',
		'New Password': 'Nouveau mot de passe',
		Email: 'Email',
		'Phone Number': 'Numéro de téléphone',
		'Confirm a Code': 'Confirmer un code',
		'Confirm Sign In': 'Confirmer la connexion',
		'Confirm Sign Up': "Confirmer l'inscription",
		'Back to Sign In': 'Retour à la connexion',
		Send: 'Envoyer',
		'Send Code': "M'envoyer un code",
		Confirm: 'Confirmer',
		SMS: 'SMS',
		'Confirm SMS Code': 'Confirmer le code SMS',
		'Confirm TOTP Code': 'Confirmer le code TOTP',
		'Resend a Code': 'Renvoyer un code',
		Submit: 'Soumettre',
		Skip: 'Passer',
		Verify: 'Vérifier',
		'Verify Contact': 'Vérifier le contact',
		Code: 'Code',
		'Confirmation Code': 'Code de confirmation',
		'Account recovery requires verified contact information':
			'La récupération du compte nécessite des informations de contact vérifiées',
		'User does not exist.': "L'utilisateur n'existe pas.",
		'User already exists.': "L'utilisateur existe déjà.",
		'Incorrect username or password': 'Identifiant ou mot de passe incorrect',
		'Invalid password format': 'Format de mot de passe invalide',
		'Invalid phone number format': `Format de numéro de téléphone invalide. Veuillez utiliser un format +12345678900`,
		'Username/client id combination not found.': "L'utilisateur n'existe pas",
		'Network error': 'Erreur réseau',
		'Sign in to your account': 'Connexion à votre compte',
		'Forgot your password? ': 'Mot de passe oublié ? ',
		'Reset password': 'Réinitialisez votre mot de passe',
		'No account? ': 'Pas de compte ? ',
		'Create account': 'Créer un compte',
		'Create Account': 'Créer un compte',
		'Have an account? ': 'Déjà un compte ? ',
		'Sign in': 'Se connecter',
		'Create a new account': 'Créer un nouveau compte',
		'Reset your password': 'Réinitialisez votre mot de passe',
		'Enter your username': "Saisissez votre adresse email",
		'Enter your password': 'Saisissez votre mot de passe',
		'Enter your phone number': 'Saisissez votre numéro de téléphone',
		'Enter your email': 'Saisissez votre adresse email',
		'Enter your code': 'Saisissez cotre code de confirmation',
		'Lost your code? ': 'Vous avez perdu votre code ? ',
		'Resend Code': 'Renvoyer le code',
		or: 'ou',
		'An account with the given email already exists.':
			'Un utilisateur avec cette adresse email existe déjà.',
		'Username cannot be empty': "Le nom d'utilisateur doit être renseigné",
  }
}