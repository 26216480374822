<div *ngIf="authState !== 'signedin'">
    <div class="p-mt-4">
        <h2>Familiarus: logiciel de généalogie facile</h2>
    </div>
    <amplify-authenticator>
        <amplify-sign-up
            slot="sign-up"
            header-text="S'inscrire"
            usernameAlias="email"
            [formFields]="signUpFormFields"
            submitButtonText="S'inscrire">
        </amplify-sign-up>
        <amplify-sign-in 
            slot="sign-in" 
            header-text="Se connecter"
            [formFields]="signUpFormFields"
            usernameAlias="email"
            submitButtonText="Se connecter">
        </amplify-sign-in>
    </amplify-authenticator>
    <app-terms-conditions *ngIf="showTC"></app-terms-conditions>
</div>
<div *ngIf="authState === 'signedin' && user" class="App">
    <router-outlet></router-outlet>
</div>
