/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:49df6884-4635-4449-945b-ae5efd254620",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_0Kcd3Rl7q",
    "aws_user_pools_web_client_id": "3sk0lg9hbj1s5s9obikcpjhfkg",
    "oauth": {},
    "aws_user_files_s3_bucket": "familytree-app135143-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
